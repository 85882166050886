// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import 'jquery'
import 'bootstrap'
import AOS from 'aos'
import 'waypoints/lib/jquery.waypoints'
import 'owl.carousel'

// // Accesibility JS
import '../custom/accessibility/accessibility'
import '../custom/accessibility/font_size'


// import "../custom/owl.carousel.min";
import typeWriter from "../custom/typeWriter"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).ready(function(event){
    AOS.init({
        duration: 800,
        easing: 'slide-up',
        once: true
    });

    $('nav .dropdown').hover(function(){
        var $this = $(this);
        $this.addClass('show');
        $this.find('> a').attr('aria-expanded', true);
        $this.find('.dropdown-menu').addClass('show');
    }, function(){
        var $this = $(this);
            $this.removeClass('show');
            $this.find('> a').attr('aria-expanded', false);
            $this.find('.dropdown-menu').removeClass('show');
    });

    const logoMenuWaypoint = new Waypoint({
        element: ($("#animated-logo").get(0) || $('body').get(0)),
        handler: function(direction){
            if(direction === "down"){
                $('.navbar-brand').fadeIn();
            }else{
                $('.navbar-brand').fadeOut();
            }
        }
    });

    document.querySelectorAll('.typed').forEach((el, i) => {
        const sectionWaypoint = new Waypoint({
            element: el,
            handler: function(direction){
                if(direction === "down"){
                    sectionWaypoint.disable();
                    $(el).attr('id', 'typed-' + i);
                    typeWriter("#typed-" + i , "true", 50);
                }
            },
            offset: '90%'
        });
    });

    $('#partners-carousel').owlCarousel({
        loop:false,
        nav:false,
        responsiveClass:true,
        autoHeight:true,
        responsive:{
            0:{
                items:2,
                margin:0,
            },
            767:{
                items:4,
                margin:50,
            },
            991:{
                items:6,
                margin:50,
            }
        }
    });

    function setTeamCarouselTitle(){
        const title = $('#about-page #team .owl-item.active.center img').data('title');
        if(title){
            $('#about-page #team .carousel-title').html(title);
            typeWriter('#about-page #team .carousel-title', "true", 50);
        }
    }
    $('#team-carousel').owlCarousel({
        loop:true,
        nav:false,
        responsiveClass:true,
        autoHeight:true,
        center: true,
        responsive:{
            0:{
                items:1,
                margin:10,
            },
            991:{
                items:2,
                margin:50,
            }
        },
        onTranslated: setTeamCarouselTitle,
        onInitialized: setTeamCarouselTitle
    });

    // Add smooth scrolling on all links inside the navbar
    $(".navbar a").on('click', smoothScrool);

    function smoothScrool(event) {

        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "" && /^#/.test(this.getAttribute('href'))) {

            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;
            
            $('button.close').click();

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
                scrollTop: $(hash).offset().top - 70
            }, 800,function () {
                history.pushState("", document.title, window.location.pathname
                                                        + window.location.search);
            });

        } // End if
    }

    // Add smooth scrolling at start page
    var hash = window.location.hash;
    if(hash !== ""){
        $('html, body').animate({
            scrollTop: $(hash).offset().top - 70
        }, 800,function () {
            history.pushState("", document.title, window.location.pathname
                                                       + window.location.search);
        });
    }
});


// initialize stellar parallax if is not in mobile
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};