import Cookies from 'js-cookie'

$(document).ready(function () {
    let contrastHabilited = false;
    let nagivationHabilited = true;

    $('#btnAltoContraste').click(function () {
        if(contrastHabilited) {
            $("body").removeClass("contrast");
            Cookies.set("contrastHabilited", false);
            contrastHabilited = false;
        } else {
            $("body").addClass("contrast");
            Cookies.set("contrastHabilited", true);
            contrastHabilited = true;
        }
    });

    $('#btnNavigation').click(function () {
        if(nagivationHabilited) {
            $("#main-navbar").removeClass("accesskey");
            nagivationHabilited = false;
        } else {
            $("#main-navbar").addClass("accesskey");
            nagivationHabilited = true;
        }
    });

    contrastHabilited = Cookies.get("contrastHabilited") === "true";

    if(contrastHabilited){
        $("body").addClass("contrast");
    }else{
        $("body").removeClass("contrast");
    }
});
