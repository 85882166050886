import Cookies from 'js-cookie'

/*
* If you want not allow resize, add class 'no-resizable'
* to your html tag
*/

const tagsName = [
    "div",
    "p",
    "span",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "li",
    "a",
    "table",
    "input",
    "textarea",
    "label",
    "legend",
];

//--------------------- Size Variables

var actualIncrement;            // Default Size + 0
var maxRangeIncrement = 7;     // Default Size + 10px
var minRangeDecrement = -5;     // Default Size - 5px
const stepValue = 1;            // 1px


//---------------------- Manipulation Functions

function setSizeToDefault(){
    incrementSize(-actualIncrement);
    Cookies.set('actualIncrement', actualIncrement);
}

function incrementSize(increment){
    for(let i=0; i < tagsName.length; i++){
        $(tagsName[i]).each(function(){
            if ($(this).hasClass("no-resizable")){
                return;
            }

            let size = parseInt($(this).css("font-size").replace("px",""));

            $(this).css("font-size", (size + increment));
        });
    }
    actualIncrement += increment;
}

//---------------------- Load localStorage and set default
$(document).ready(function () {

    //Load Default size for security, if there is a load fail or other problems
    actualIncrement = 0;
    incrementSize(0);

    $('#btndecreaseSize').click(function () {
        if(actualIncrement > minRangeDecrement){
            incrementSize(-stepValue);
            Cookies.set('actualIncrement', actualIncrement);
        }
    });

    $('#btnincreaseSize').click(function () {
        if(actualIncrement < maxRangeIncrement){
            incrementSize(+stepValue);
            Cookies.set('actualIncrement', actualIncrement);
        }
    });

    $('#btnsetSizeToDefault').click(function () {
        setSizeToDefault();
    });

    if(Cookies.get('actualIncrement')){
        incrementSize(parseInt(Cookies.get('actualIncrement')));
    }else{
        setSizeToDefault();
        Cookies.set('actualIncrement', actualIncrement);
    }


});
